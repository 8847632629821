<template>
  <div class="page-layout" v-if="pageData && pageData.attributes">

    <HeaderIntroImage underlined :headerMetadata="pageData"></HeaderIntroImage>
    
    <div class="container" v-if="pageData.attributes.field_page_main_content">
      <div class="page-body list-2 blue-title" v-html="this.pageData.attributes.field_page_main_content.value"></div>
    </div>

  </div>
</template>

<script>
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import { fetchSinglePage } from '../libs/drupalClient.js'
export default {
  name: 'not-found',
  components: {
    HeaderIntroImage
  },
  data() {
    return {
      pageData: {
        attributes: {
          title: "Privacy Policy"
        }
      },
      currentPageID: "e29a286d-7678-42c6-a773-d06848fe90f8"
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    }
  },
  mounted() {
    fetchSinglePage(this.currentPageID, {
      include: ['field_ref_page_content', 'field_ref_image', 'field_attachments']
    })
    .then(res => { this.pageData = res })
  }
}
</script>

<style lang="scss" scoped>
::v-deep h4 { margin-top: 2rem; }
::v-deep h3 { margin-top: 3rem; }
::v-deep h2 { margin-top: 4rem; }
</style>